.cta {

    background: lime;
    padding: 40px;

    a {
        background-color: rgba(0,0,255, .5);
        color: #fff;
        padding: 10px 15px;
        border-radius: 5px;
        text-decoration: none;
        cursor: pointer;
        transition: 0.5s ease all;

        &:hover {
            background-color: rgba(0,0,255, .5);
            color: #fff;
            box-shadow: 5px 5px 5px rgba(0,0,0,.5);
        }
    }
}