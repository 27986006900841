* {
    margin: 0;
    padding: 0;
    font-family: "Chakra Petch", sans-serif;
    box-sizing: border-box;
}

.button {
    background-color: lime;
    color: #000;
    padding: 15px;
    border-radius: 5px;
    transition: .5s ease all;
    display: inline-block;
    text-decoration: none;
    margin: 10px;
    border: #ccc;

    &:hover {
        background-color: rgba(0,0,255, .5);
        color: #fff;
        box-shadow: 5px 5px 5px rgba(0,0,0,.5);
    }
}

footer {
    width: 100%;
    padding: 25px;
    text-align: center;
    background: #e6e6e6;
}

h1, h2, h3, h4, h5 {
    font-family: "Chakra Petch", sans-serif;
    padding-top: 15px;
}

p {
    padding: 10px 0;
}

a {
    color: #00d600;
    transition: 0.5s ease all;

    &:hover {
        color: #154d14;
    }
}

.columns {

    iframe {
        margin:0 auto;
    }
}

.centered {
    text-align: center;
}

.container {
    margin: 0 25px;
    padding: 25px 10px;
    min-height: 80vh;

    ul {
        margin-left: 25px;
    }

    &.short {
        min-height: auto;
    }
}

footer {
    a {
        color: #666;
        transition: 0.5s ease all;

        &:hover {
            color: #000;
        }
    }
}

.home {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    img {
        padding: 25px;
        max-width: 85vw;
        margin: 0 auto;
    }
}

.schedule-table {
    width: 100%;
    text-align: left;

    th, td {
        padding: 5px;
    }
}

.contact {
    h2 {
        padding: 30px 0;
    }
    h3 {
        padding-top: 30px;
        line-height: 1rem;

        span {
            font-weight:100;
        }
    }

    a {
        font-weight: bold;
        line-height: 2.5rem;
    }
}

@media screen and (min-width: 767px) { 
    .container {
        margin: 0 auto;
        width: 90%;
        max-width: 700px;
    }

    .columns {
        display:flex;
        flex-direction: row;
        width: 100%;
        align-items: center;;


        .column {
            display: flex;
            flex-direction: column;
            width: 48%;
            text-align: center;
        }

    }

    .home {
        flex-direction: row;
        width: 100%;
    }

}


@media screen and (min-width: 1200px) { 
    .container {
        max-width: 1180px;
    }

}